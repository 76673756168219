import restaurant from "./images/salut.jpg";
import img_card from "./images/img2.jpg";
import imgOmSuflet from "./images/h9.jpg";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/5hm.jpg";
import imgheaderm from "./images/5h.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";

{/*anusic007@gmail.com*/}
{/** message1: "P.S. Vă vom fi recunoscători dacă tradiţionalul buchet de flori va fi înlocuit cu flori în ghiveci!",*/} 
const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Victor",
        mireasa: "Lidia",
        data: "6 August 2022",
        data_confirmare: "29 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "lidiamardari96@gmail.com", 
       tel: "+373xxx xxx xx",
       phone: "tel:+373xxx",
       viber: "viber://chat?number=%2B373xxx",
       whatsapp: "https://wa.me/+373xxx5",
       messenger: "https://www.messenger.com/t/viteoc.ivascu",
       tel1: "+447564036787",
       phone1: "tel:+447564036787",
       viber1: "viber://chat?number=%2B447564036787",
       whatsapp1: "https://wa.me/+447564036787",
       messenger1: "https://www.messenger.com/t/lida.mardari.7",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "La Salut",
            data: "6 august 2022,",
            ziua: "sâmbătă,",
            ora: "ora: 19:00",
            adress: "r-l Nisporeni, Moldova",
            harta: "https://goo.gl/maps/MaLnZE6bghv6pAuH8",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d173749.01960923476!2d28.174387740845397!3d47.122033729209456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cbb3766fbe9afd%3A0xabf199e1457fd47b!2zVGFixINyYSBTYWx1dCwgUjI1LjEsIFZhcnphcmVzdGksINCc0L7Qu9C00LDQstC40Y8!5e0!3m2!1sro!2s!4v1655367689360!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
       
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            nasii: "CU BINECUVÂNTAREA ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Valeriu & Cristina",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;